.body {
  padding-bottom: 0;
  padding-top: 32px;
}

@media screen and (max-width: 768px) {
  .body {
    padding: 16px;
  }
}
